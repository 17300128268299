import apiList from '@/api';

export default function({ $axios, store }, inject) {
  const fileUpload = function(url, para, callback) {
    let formData = new FormData();
    formData.append('id', para.id);
    formData.append('file', para.file);
    if (para.type) {
      formData.append('type', para.type);
    }

    $axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => {
        let data = response.data;
        if (response.code == 202) {
        } else if (response.code == 100) {
          if (callback) callback(data);
        } else if (data) {
          Message.error(data.msg + ':' + data);
        } else {
          Message.error(data.msg);
        }
      });
  };

  // Create a custom axios instance
  const api = (name, payload = {}, callback = () => {}) =>
    new Promise((resolve, reject) => {
      const { method, url } = apiList[name](payload);

      switch (method) {
        case 'POST':
          $axios
            .post(url, payload)
            .then(({ data }) => resolve(data))
            .catch(error => reject(error));
          break;
        case 'GET':
          $axios
            .get(url, { params: payload })
            .then(({ data }) => resolve(data))
            .catch(error => reject(error));
          break;
        case 'PUT':
          return $axios
            .put(url, payload)
            .then(({ data }) => resolve(data))
            .catch(error => reject(error));
        case 'PATCH':
          return $axios
            .patch(url, payload)
            .then(({ data }) => resolve(data))
            .catch(error => reject(error));
        case 'DELETE':
          return $axios
            .delete(url, { params: payload })
            .then(({ data }) => resolve(data))
            .catch(error => reject(error));
        case 'FILE':
          return fileUpload(url, payload, callback);
        default:
          return $axios
            .post(url, payload)
            .then(({ data }) => resolve(data))
            .catch(error => reject(error));
      }
    });

  // Inject to context as $api
  inject('api', api);
}
