//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import dialogMixin from '@/mixin/dialog';
import { required, username, password } from '~/utils/validation';
import Captcha from '~/components/Captcha';
import GoogleLogin from '~/components/LoginButton/GoogleLogin';

export default {
  name: 'LoginDialog',
  mixins: [dialogMixin],
  components: { Captcha, GoogleLogin },
  data() {
    return {
      dataForm: {},
      dataFormCompany: {},
      isCaptchaOverTime: false,
      rules: {
        username: [required, username],
        password: [required, password],
        captcha: [required],
      },
    };
  },
  computed: {
    ...mapGetters('user', ['token', 'captchaCode']),
    ...mapGetters('dialog', ['loginShow', 'loginActiveTab']),
    isGoogleLoginOpen() {
      return process.env.isGoogleLoginOpen;
    },
    isBusinessOpen() {
      return process.env.isBusinessOpen;
    },
    isLecturerOpen() {
      return process.env.isLecturerOpen;
    },
    isLoginShow: {
      get: function () {
        return this.loginShow;
      },
      set: function (newValue) {
        this.setLoginShow(newValue);
      },
    },
    isMember() {
      return this.loginActiveTab === 0;
    },
    isOwner() {
      return this.loginActiveTab === 1;
    },
    isLecturer() {
      return this.loginActiveTab === 2;
    },
    loginPath() {
      return ['userLogin', 'ownerLogin', 'lecturerLogin'][this.loginActiveTab];
    },
    labelPosition() {
      if (process.browser) {
        return window.innerWidth > 768 ? 'left' : 'top';
      }
      return 'left';
    },
  },
  methods: {
    ...mapActions('user', [
      'setToken',
      'setType',
      'setUserName',
      'setUserEngName',
      'setUserIdNumber',
      'setEmail',
      'setAvatarUrl',
      'setOid',
      'setUserCategory',
      'setUserStatus',
      'setCaptchaImg',
    ]),
    ...mapActions('personal', ['setBasicData', 'setIsEdit']),
    ...mapActions('dialog', [
      'setLoginShow',
      'setLoginActiveTab',
      'setRegisterShow',
    ]),
    onClickTab(value) {
      this.setLoginActiveTab(value);
    },
    handleSubmit() {
      let callback = data => {
        this.setToken(data);
        this.setType(data.type);
        this.setUserName(data.name || data.ownerName || '');
        this.setUserEngName(data.engName || '');
        this.setUserIdNumber(data.idNumber || '');
        this.setEmail(data.email || '');
        this.setAvatarUrl(data.url || '');
        this.setOid(data.oid || '');
        this.setUserCategory(data.category || this.loginActiveTab || 0);
        this.setUserStatus(data.status);
        this.setLoginShow(false);
        this.$api('userGetUser').then(userData => {
          this.setBasicData(userData);
          this.setAvatarUrl(userData.url);
          this.setIsEdit(false);
        });
        this.close();
      };
      this.$refs.dataForm.validate(valid => {
        if (!valid) {
          return false;
        }

        if (this.isCaptchaOverTime) {
          this.$message.error('驗證碼已過期，請刷新後再次輸入');
          return false;
        }

        let body = {
          username: this.dataForm.username,
          password: this.dataForm.password,
          code: this.captchaCode,
          captcha: this.dataForm.captcha.replace(/ /g, ''),
        };

        this.$api(this.loginPath, body)
          .then(data => {
            this.$message.success('登入成功!');
            callback(data);
          })
          .catch(() => {
            this.setCaptchaImg();
          });
      });
    },
    handleGoogleLogin() {
      this.setLoginShow(false);
      this.close();
    },
    onClickLogin() {
      this.$gtag.event('登入', {
        event_category: '按鈕點擊',
        event_label: '登入',
        value: this.dataForm.idNumber,
      });
      return this.handleSubmit();
    },
    openRegister() {
      this.onClose();
      this.setRegisterShow(true);
      this.$gtag.event('打開註冊彈窗', {
        event_category: '按鈕點擊',
        event_label: '打開註冊彈窗',
        value: this.userName,
      });
    },
    onClickForgot() {
      console.log('🚀 ~ onClickForgot ~ onClickForgot');
    },
    onOpen() {
      this.setCaptchaImg(false);
    },
    onClose() {
      this.dataForm = {};
      this.$refs['dataForm'].clearValidate();
      this.setLoginShow(false);
      this.close();
    },
    captchaOverTime(value) {
      this.isCaptchaOverTime = value;
    },
  },
};
