//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NavHeader from '~/components/NavHeader';
import NavFooter from '~/components/NavFooter';
import NavAside from '~/components/NavAside';
import LoginDialog from '~/components/Dialog/LoginDialog';
import RegisterDialog from '~/components/Dialog/RegisterDialog';
export default {
  name: 'App',
  components: {
    NavHeader,
    NavFooter,
    NavAside,
    LoginDialog,
    RegisterDialog,
  },
};
