//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'NavHeaderOther',
  methods: {
    goto(path) {
      this.$router.push(path);
    },
  },
};
