import { Message } from 'element-ui';
import download from 'downloadjs';

export const BASE_URL =
  process.env.NODE_ENV === 'development' ? 'https://dev.step1ne.com' : '';

export default function({ $axios, store }) {
  let refreshing = false;
  // Change URL only for client
  if (process.client) {
    $axios.setBaseURL(BASE_URL);
  }

  // Change URL only for server
  if (process.server) {
    $axios.setBaseURL('https://step1ne.com/apis');
  }

  // setHeader
  $axios.setHeader('Content-Type', 'application/json');

  // onRequest
  $axios.onRequest(config => {
    const { url } = config;

    return new Promise((resolve, reject) => {
      let category = store.getters['user/userCategory'] || 0;
      // category 0:一般, 1:企業, 2:講師
      // 登入時其他系統api不帶token
      if (category === 0 && !url.includes('apis')) return resolve(config);
      if (category === 1 && !url.includes('business')) return resolve(config);
      if (category === 2 && !url.includes('lecturer')) return resolve(config);

      let token = store.getters['user/token'];
      if (token) {
        config.headers.Authorization = 'Bearer ' + token;
      }

      resolve(config);
    });
  });

  // onResponse
  $axios.onResponse(response => {
    if (response.headers['content-type'] === 'application/pdf') {
      let match = response.headers['content-disposition'].match(
        /filename=(.*)/,
      );
      download(response.data, decodeURIComponent(match[1]));
    }
    if (response.data.code == 999999) {
      return Promise.resolve({ data: response.data.message });
    }
    if (response.data.code == 202) {
      let originalRequest = response.config;
      if (refreshing) {
        setTimeout(() => {
          $axios(originalRequest);
        }, 500);
      } else {
        refreshing = true;
        let refreshTokeUrl = '/apis/auth/refresh';
        if (originalRequest.url !== refreshTokeUrl) {
          const payload = { refreshToken: store.getters['user/refreshToken'] };
          $axios
            .post(refreshTokeUrl, payload) // refresh_toke is attached in cookie
            .then(res => {
              if (res.code == 206) {
                refreshing = false;
                store.dispatch('user/logout', 'expired');
                return Promise.reject('');
              }
              store.dispatch('user/setToken', res.data);
              originalRequest.headers.Authorization =
                'Bearer ' + res.data.accessToken;
              refreshing = false;
              return $axios(originalRequest);
            })
            .catch(err => {
              refreshing = false;
              store.dispatch('user/logout', 'expired');
              return Promise.reject();
            });
        }
      }
    } else if (response.data.code == 206 || response.data.code == 104) {
      return Promise.reject();
    } else if (response.status != 200 || response.data.code != 100) {
      Message.error(response.data.message);
      return Promise.reject(response.data.message);
    }

    return Promise.resolve(response.data);
  });

  // onError
  $axios.onError(error => {
    return Promise.reject(error);
  });
}
