// initial state
const state = () => ({
  loginShow: false,
  registerShow: false,
  loginActiveTab: 0,
  registerActiveTab: 0,
  rulesShow: false,
  actShow: false,
  termsShow: false,
});

// getters
const getters = {
  loginShow: state => state.loginShow,
  registerShow: state => state.registerShow,
  loginActiveTab: state => state.loginActiveTab,
  registerActiveTab: state => state.registerActiveTab,
  rulesShow: state => state.rulesShow,
  actShow: state => state.actShow,
  termsShow: state => state.termsShow,
};

// actions
const actions = {
  setLoginShow({ commit }, payload) {
    commit('SET_LOGIN_SHOW', payload);
  },
  setRegisterShow({ commit }, payload) {
    commit('SET_REGISTER_SHOW', payload);
  },
  setLoginActiveTab({ commit }, payload) {
    commit('SET_LOGIN_ACTIVE_TAB', payload);
  },
  setRegisterActiveTab({ commit }, payload) {
    commit('SET_REGISTER_ACTIVE_TAB', payload);
  },
  setRulesShow({ commit }, payload) {
    commit('SET_RULES_SHOW', payload);
  },
  setActShow({ commit }, payload) {
    commit('SET_ACT_SHOW', payload);
  },
  setTermsShow({ commit }, payload) {
    commit('SET_TERMS_SHOW', payload);
  },
};

// mutations
const mutations = {
  SET_LOGIN_SHOW(state, data) {
    sessionStorage.setItem('_loginShow', data);
    state.loginShow = data;
  },
  SET_REGISTER_SHOW(state, data) {
    sessionStorage.setItem('_registerShow', data);
    state.registerShow = data;
  },
  SET_LOGIN_ACTIVE_TAB(state, data) {
    sessionStorage.setItem('_loginActiveTab', data);
    state.loginActiveTab = data;
  },
  SET_REGISTER_ACTIVE_TAB(state, data) {
    sessionStorage.setItem('_registerActiveTab', data);
    state.registerActiveTab = data;
  },
  SET_RULES_SHOW(state, data) {
    sessionStorage.setItem('_rulesShow', data);
    state.rulesShow = data;
  },
  SET_ACT_SHOW(state, data) {
    sessionStorage.setItem('_actShow', data);
    state.actShow = data;
  },
  SET_TERMS_SHOW(state, data) {
    sessionStorage.setItem('_termsShow', data);
    state.termsShow = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
