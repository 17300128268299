//
//
//
//
//
//
//
//
//
//

import NavHeader from '~/components/NavHeader/other';
import NavFooter from '~/components/NavFooter/other';

export default {
  name: 'App',
  components: {
    NavHeader,
    NavFooter,
  },
};
