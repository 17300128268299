//
//
//
//
//

export default {
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    hoverColor: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconName() {
      return `#icon-${this.iconClass}`;
    },
    svgClass() {
      if (this.className) {
        return 'svg-icon ' + this.className;
      } else {
        return 'svg-icon';
      }
    },
    trueHoverColor() {
      return this.hoverColor ? this.hoverColor : this.color;
    },
    fillColor() {
      switch (this.onHover ? this.trueHoverColor : this.color) {
        case 'primary':
        case 'member':
          return '#59bbe5';
        case 'primary-darken':
          return '#204064';
        case 'owner':
          return '#62ded1';
        case 'lecturer':
          return '#af8ae1';
        case 'secondary':
          return '#f7920b';
        case 'success':
          return '#71ae33';
        case 'warning':
          return '#f7920b';
        case 'danger':
          return '#e56565';
        case 'disable':
          return '#9c9595';
        case 'highlight':
          return '#e2136d';
        case 'white':
          return '#ffffff';
        case 'black':
          return '#000000';
        case 'text1':
          return '#000000';
        case 'text2':
          return '#707070';
        case 'text3':
          return '#a2a2a2';

        case '':
          return 'initial';
        default:
          return this.color;
      }
    },
  },
  data() {
    return {
      onHover: false,
    };
  },
};
