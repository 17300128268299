// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/icon/telegram.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/icon/line.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/icon/top.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body[data-v-75e6e58e]{margin:0;overflow-x:hidden;font-family:\"Noto Sans TC\",sans-serif,\"微軟正黑體\",arial}#__layout[data-v-75e6e58e],#__nuxt[data-v-75e6e58e],.container[data-v-75e6e58e],body[data-v-75e6e58e]{height:100%}.container[data-v-75e6e58e]{box-sizing:border-box;width:100%;max-width:1240px;margin:auto;padding:0 20px}@media (max-width:540px){.container[data-v-75e6e58e]{padding:0 10px}}p[data-v-75e6e58e]{margin:.25em 0;line-height:1.25em}h1[data-v-75e6e58e]{font-size:48px}h2[data-v-75e6e58e]{font-size:26px}@media (max-width:768px){h1[data-v-75e6e58e]{font-size:32px}h2[data-v-75e6e58e]{font-size:16px}}.grecaptcha-badge[data-v-75e6e58e]{visibility:hidden}.hideHeading[data-v-75e6e58e]{position:absolute;width:100%;opacity:0}.asideWrapper[data-v-75e6e58e]{display:flex;justify-content:flex-start;align-items:center;flex-direction:column;flex-wrap:nowrap;position:fixed;bottom:100px;right:10px;z-index:100}.asideWrapper .icon[data-v-75e6e58e]{width:60px;height:60px;position:relative;cursor:pointer}.asideWrapper .icon.telegram[data-v-75e6e58e]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.asideWrapper .icon.line[data-v-75e6e58e],.asideWrapper .icon.telegram[data-v-75e6e58e]{background-repeat:no-repeat;background-position:50%;background-size:contain}.asideWrapper .icon.line[data-v-75e6e58e]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.asideWrapper .icon.top[data-v-75e6e58e]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-repeat:no-repeat;background-position:50%;background-size:contain;width:50px;height:50px;position:relative;opacity:0;transition:opacity .5s}.asideWrapper .icon.top.show[data-v-75e6e58e]{opacity:1}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
