//
//
//
//
//
//
//
//
//

export default {
  name: 'googleLogin',
  methods: {
    handleGoogleLogin() {
      this.$emit('click');
      if (process.client) {
        const API_BASE_URL = `http://${
          window.location.hostname === 'step1ne.com' ? '' : 'dev-'
        }openid.step1ne.com`;

        const REDIRECT = window.location.origin + '/openid/redirect';

        const GOOGLE_AUTH_URL =
          API_BASE_URL + '/oauth2/authorize/google?redirect_uri=' + REDIRECT;

        window.open(GOOGLE_AUTH_URL, '_self');
      }
    },
  },
};
