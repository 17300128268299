//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import dialogMixin from '@/mixin/dialog.js';

export default {
  name: 'RulesDialog',
  mixins: [dialogMixin],
  computed: {
    ...mapGetters('dialog', ['rulesShow']),
  },
  methods: {
    ...mapActions('dialog', ['setRulesShow']),
    onClose() {
      this.setRulesShow(false);
    },
  },
};
