//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Member',
  data() {
    return {
      memberMenu: [
        {
          label: '個人中心',
          path: '/Personal',
        },
      ],
      ownerMenu: [
        {
          label: '管理中心',
          path: '/Owner/Manage',
        },
      ],
      lecturerMenu: [
        {
          label: '講師個人中心',
          path: '/Training/Manage',
        },
      ],
      memberMenuShow: false,
    };
  },
  computed: {
    ...mapGetters('user', [
      'userName',
      'oid',
      'userCategory',
      'avatarUrl',
      'token',
      'unreadMessage',
    ]),
    isMember() {
      return this.userCategory === 0;
    },
    isOwner() {
      return this.userCategory === 1 && this.oid !== null;
    },
    isLecturer() {
      return this.userCategory === 2;
    },
    categoryName() {
      if (!this.userCategory) return 'member';
      return ['member', 'owner', 'lecturer'][this.userCategory];
    },
    menuList() {
      switch (this.userCategory) {
        case 0:
          return this.memberMenu;
        case 1:
          return this.ownerMenu;
        case 2:
          return this.lecturerMenu;
        default:
          break;
      }
      return this.isOwner ? this.ownerMenu : this.memberMenu;
    },
    getUnreadApi() {
      switch (this.userCategory) {
        case 1:
          return 'getUnreadOwnerMessage';
        case 2:
          return 'getUnreadLecturerMessage';
        case 0:
        default:
          return 'getUnreadUserMessage';
      }
    },
    messageUrl() {
      switch (this.userCategory) {
        case 1:
          return '/Owner/Manage?tab=message';
        case 2:
          return '/Training/Manage?tab=message';
        case 0:
        default:
          return '/Personal?tab=message';
      }
    },
  },
  methods: {
    ...mapActions('user', ['logout', 'setUnreadMessage']),
    goto(path) {
      this.closeMemberMenu();
      this.$emit('goto', path);
    },
    logoutClick() {
      this.$emit('closeMenu');
      this.logout('userClick');
    },
    toggleMemberMenu() {
      this.memberMenuShow = !this.memberMenuShow;
    },
    closeMemberMenu() {
      this.memberMenuShow = false;
    },
    getUnreadMessage() {
      this.$api(this.getUnreadApi).then(data => {
        this.setUnreadMessage(data);
      });
    },
    gotoMessage() {
      return this.$router.push(this.messageUrl);
    },
  },
  mounted() {
    if (process.client && this.token) {
      this.getUnreadMessage();
    }
  },
};
