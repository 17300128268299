//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import Member from './Member';

export default {
  name: 'NavHeader',
  components: { Member },
  data() {
    return {
      menuActive: false,
    };
  },
  computed: {
    ...mapGetters('user', ['token', 'userName']),
    isBusinessOpen() {
      return process.env.isBusinessOpen;
    },
    isLecturerOpen() {
      return process.env.isLecturerOpen;
    },
    isAllowOpen() {
      return process.env.isAllowLogin;
    },
  },
  methods: {
    ...mapActions('dialog', ['setLoginShow', 'setRegisterShow']),
    ...mapActions('article', ['setCategory', 'setCurrentId']),
    linkTo(path) {
      if (process.browser) {
        window.location.href = path;
        // window.open(path);
      }
    },
    goto(path) {
      this.closeMenu();

      if (path === '/Article') {
        this.setCategory('news');
        this.setCurrentId(0);
      }
      if (path === this.$route.path) return;

      setTimeout(
        () => {
          this.$router.push(path);
        },
        this.menuActive ? 100 : 0,
      );
    },
    toggleMenu() {
      this.menuActive = !this.menuActive;
    },
    closeMenu() {
      this.menuActive = false;
    },
    openLogin() {
      this.closeMenu();
      this.setLoginShow(true);
      this.$gtag.event('打開登入彈窗', {
        event_category: '按鈕點擊',
        event_label: '打開登入彈窗',
        value: this.userName,
      });
    },
    openRegister() {
      this.closeMenu();
      this.setRegisterShow(true);
      this.$gtag.event('打開註冊彈窗', {
        event_category: '按鈕點擊',
        event_label: '打開註冊彈窗',
        value: this.userName,
      });
    },
  },
};
