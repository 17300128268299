//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import dialogMixin from '@/mixin/dialog.js';

export default {
  name: 'ActDialog',
  mixins: [dialogMixin],
  computed: {
    ...mapGetters('dialog', ['actShow']),
  },
  methods: {
    ...mapActions('dialog', ['setActShow']),
    onClose() {
      this.setActShow(false);
    },
  },
};
