export default {
  // 登入
  userLogin: () => ({ method: 'POST', url: '/apis/auth/login' }),

  // 註冊
  userRegister: () => ({ method: 'POST', url: '/apis/auth/register' }),

  // 驗證碼圖片
  captchaImg: () => ({ method: 'GET', url: '/apis/auth/captcha' }),

  // 上傳履歷註冊 - 上傳檔案
  registerUploadFile: () => ({ method: 'FILE', url: '/apis/auth/uploadFile' }),

  // 帳號認證
  authVerify: () => ({ method: 'GET', url: '/apis/auth/verify' }),

  // 取消訂閱
  unsubscribe: () => ({ method: 'GET', url: '/apis/newsletter/unsubscribe' }),

  // 登出
  userLogout: () => ({ method: 'POST', url: '/apis/user/logout' }),

  // 取得輪播圖片
  getBanners: () => ({ method: 'GET', url: '/apis/banner/getBanners' }),

  // ############## 全域使用 ##############

  // 銀行清單
  getBankSelect: () => ({ method: 'GET', url: '/apis/bank/select' }),

  // 產業類別下拉式選單
  getIndustryCategoryTree: () => ({
    method: 'GET',
    url: '/apis/industryCategory/tree',
  }),

  // ############## 企業 ##############

  // 前10熱門企業列表
  getCompanyPopular: () => ({ method: 'GET', url: '/apis/owner/popular' }),

  // 企業搜索
  searchCompany: () => ({ method: 'POST', url: '/apis/owner/search' }),

  // 取得企業資訊
  getCompany: ({ oid }) => ({ method: 'GET', url: `/apis/owner/${oid}` }),

  // ############## 搜索職缺 ##############

  // 取得國家地區 flag = 0:國內 1:海外
  getArea: () => ({ method: 'GET', url: '/apis/area/tree' }),

  // 取得職業領域
  getJobCat: () => ({ method: 'GET', url: '/apis/job-cat/tree' }),

  // 熱門區域列表
  getAreaPopular: () => ({ method: 'GET', url: '/apis/area/popular' }),

  // 熱門關鍵字
  getKeywords: () => ({ method: 'POST', url: '/apis/keyword/popular' }),

  // 搜索職缺
  searchJobs: () => ({ method: 'POST', url: '/apis/job-position/search' }),

  // 職缺詳細資訊
  getJob: ({ id }) => ({ method: 'GET', url: `/apis/job-position/${id}` }),

  // 職缺分享資訊頁
  getShareJob: ({ id }) => ({
    method: 'GET',
    url: `/apis/job-position/public/information/${id}`,
  }),

  // 技能樹
  getListSkillTree: () => ({ method: 'GET', url: '/apis/skill/tree' }),

  // 會員訪問被分享職缺
  accessShareJob: () => ({ method: 'POST', url: '/apis/user/accessShareJob' }),

  // ############## 文章 ##############

  // 依照文章分類取得文章
  getArticleCat: () => ({
    method: 'GET',
    url: '/apis/article/getArticleByCategory',
  }),

  // 取得隨機文章
  getRandomArticle: () => ({
    method: 'GET',
    url: '/apis/article/getRandomArticle',
  }),

  // 取得單筆文章資料
  getOneArticle: () => ({ method: 'GET', url: '/apis/article/getOne' }),

  // 文章分頁查詢
  getArticleList: () => ({ method: 'POST', url: '/apis/article/list' }),

  // ############## 會員中心 ##############

  // 會員中心-基本資料-取得基本資料
  userGetUser: () => ({ method: 'GET', url: '/apis/user/getUser' }),

  // 會員中心-基本資料-修改基本資料
  userUpdateUser: () => ({ method: 'POST', url: '/apis/user/updateUserInfo' }),

  // 會員中心-上傳頭像
  uploadAvatar: () => ({
    method: 'FILE',
    url: '/apis/user/uploadPic',
  }),

  // 會員中心-基本資料-取得家庭成員列表
  userGetFamilyList: () => ({
    method: 'GET',
    url: '/apis/userFamilyInfo/list',
  }),

  // 會員中心-基本資料-新增家庭成員
  userAddFamily: () => ({ method: 'POST', url: '/apis/userFamilyInfo/add' }),

  // 會員中心-基本資料-修改家庭成員
  userUpdateFamily: () => ({
    method: 'POST',
    url: '/apis/userFamilyInfo/update',
  }),

  // 會員中心-基本資料-刪除家庭成員
  userDeleteFamily: () => ({
    method: 'POST',
    url: '/apis/userFamilyInfo/delete',
  }),

  // 會員中心-取得會員檔案
  // type: 0 = 履歷表, 1 = 面試紀錄, 2 = 離職證明
  getUserFiles: () => ({ method: 'GET', url: '/apis/user/files' }),

  // 會員中心-上傳會員檔案
  uploadUserFile: () => ({
    method: 'FILE',
    url: '/apis/user/uploadUserResumeFile',
  }),

  // 會員中心-移除會員檔案
  removeUserFile: () => ({ method: 'DELETE', url: '/apis/user/files' }),

  // 會員中心-我的履歷-取得履歷資訊
  userGetUserResume: () => ({ method: 'GET', url: '/apis/user/getUserResume' }),

  // 會員中心-我的履歷-修改履歷資訊
  userUpdateUserResume: () => ({
    method: 'POST',
    url: '/apis/user/updateUserResume',
  }),

  // 會員中心-我的履歷-取得學歷列表
  userGetEducationList: () => ({
    method: 'GET',
    url: '/apis/userEducation/list',
  }),

  // 會員中心-基本資料-新增學歷
  userAddEducation: () => ({ method: 'POST', url: '/apis/userEducation/add' }),

  // 會員中心-基本資料-修改學歷
  userUpdateEducation: () => ({
    method: 'POST',
    url: '/apis/userEducation/update',
  }),

  // 會員中心-基本資料-刪除學歷
  userDeleteEducation: () => ({
    method: 'POST',
    url: '/apis/userEducation/delete',
  }),

  // 會員中心-基本資料-Email認證
  sendEmailVerify: () => ({
    method: 'GET',
    url: '/apis/user/sendEmailVerify',
  }),

  // 會員中心-我的履歷-取得工作經歷列表
  userGetExperienceList: () => ({
    method: 'GET',
    url: '/apis/userExperience/list',
  }),

  // 會員中心-基本資料-新增工作經歷
  userAddExperience: () => ({
    method: 'POST',
    url: '/apis/userExperience/add',
  }),

  // 會員中心-基本資料-修改工作經歷
  userUpdateExperience: () => ({
    method: 'POST',
    url: '/apis/userExperience/update',
  }),

  // 會員中心-基本資料-刪除工作經歷
  userDeleteExperience: () => ({
    method: 'POST',
    url: '/apis/userExperience/delete',
  }),

  // 會員中心-我的履歷-取得會員技能列表
  userGetSkillList: () => ({ method: 'POST', url: '/apis/userSkill/list' }),

  // 會員中心-基本資料-新增會員技能
  userAddSkill: () => ({ method: 'POST', url: '/apis/userSkill/add' }),

  // 會員中心-基本資料-刪除會員技能
  userDeleteSkill: () => ({ method: 'POST', url: '/apis/userSkill/delete' }),

  // 會員中心-我的履歷-取得語言列表
  userGetLanguageList: () => ({
    method: 'GET',
    url: '/apis/userLanguage/list',
  }),

  // 會員中心-基本資料-新增語言
  userAddLanguage: () => ({ method: 'POST', url: '/apis/userLanguage/add' }),

  // 會員中心-基本資料-修改語言
  userUpdateLanguage: () => ({
    method: 'POST',
    url: '/apis/userLanguage/update',
  }),

  // 會員中心-基本資料-刪除語言
  userDeleteLanguage: () => ({
    method: 'POST',
    url: '/apis/userLanguage/delete',
  }),

  // 會員中心-應徵職缺
  userAddApplicant: () => ({ method: 'POST', url: '/apis/userApplicant/add' }),

  // 會員中心-應徵記錄
  userGetApplicantList: () => ({
    method: 'GET',
    url: '/apis/userApplicant/list',
  }),

  // 會員中心-取消應徵記錄
  cancelUserApplicant: ({ id }) => ({
    method: 'PATCH',
    url: `/apis/userApplicant/${id}/cancel`,
  }),

  // 會員中心-收藏記錄
  userGetFavoriteJobList: () => ({
    method: 'GET',
    url: '/apis/user/favorite/job',
  }),

  // 會員中心-收藏記錄-新增
  userAddFavoriteJob: () => ({
    method: 'POST',
    url: '/apis/user/favorite/job',
  }),

  // 會員中心-收藏記錄-移除
  userRemoveFavoriteJob: () => ({
    method: 'DELETE',
    url: '/apis/user/favorite/job',
  }),

  // 會員中心-推薦記錄
  userGetReferList: () => ({ method: 'GET', url: '/apis/user/refer' }),

  // 會員中心-推薦-取得會員分享碼
  userGetReferCode: () => ({ method: 'GET', url: '/apis/user/referCode' }),

  // 會員中心-提领記錄
  userGetWithdrawAmount: () => ({
    method: 'GET',
    url: '/apis/userWithdrawInfo/getWithdrawAmount',
  }),

  // 會員中心-申請款項
  userAddWithdraw: () => ({
    method: 'POST',
    url: '/apis/userWithdrawInfo/addWithdrawHistory',
  }),

  // 會員中心-取得提款資訊
  userGetWithdrawInfo: () => ({
    method: 'GET',
    url: '/apis/userWithdrawInfo/getWithdrawInfo',
  }),

  // 會員中心-新增提款資訊
  userAddWithdrawInfo: () => ({
    method: 'POST',
    url: '/apis/userWithdrawInfo/add',
  }),

  // 會員中心-提款紀錄
  userListWithdraw: () => ({
    method: 'POST',
    url: '/apis/userWithdrawInfo/listAll',
  }),

  // ############## 瀏覽紀錄 ##############

  // 新增瀏覽職缺
  addUserBrowseHistory: () => ({
    method: 'POST',
    url: '/apis/userBrowseHistory/add',
  }),

  // 取得瀏覽紀錄
  getUserBrowseHistory: () => ({
    method: 'GET',
    url: '/apis/userBrowseHistory/list',
  }),

  // 移除瀏覽職缺
  removeUserBrowseHistory: ({ id }) => ({
    method: 'PATCH',
    url: `/apis/userBrowseHistory/${id}/cancel`,
  }),

  // ############## 聯絡我們 ##############

  // 取得聯絡紀錄
  getMail: () => ({
    method: 'GET',
    url: '/apis/mail/send',
  }),

  // 送出聯絡我們
  sendMail: () => ({
    method: 'POST',
    url: '/apis/mail/sendMail',
  }),

  // ############## 企業訊息 ##############

  // 發送站內信
  sendUserMessage: () => ({
    method: 'POST',
    url: '/apis/messageSender/add',
  }),

  // 刪除站內信
  deleteUserMessage: () => ({
    method: 'DELETE',
    url: '/apis/messageSender/delete',
  }),

  // 取得站內信列表
  getUserMessage: () => ({
    method: 'POST',
    url: '/apis/messageSender/findByCondition',
  }),

  // 讀取信件
  readUserMessage: ({ rId }) => ({
    method: 'PATCH',
    url: `/apis/messageSender/read?rId=${rId}`,
  }),

  // 回覆站內信
  replyUserMessage: () => ({
    method: 'POST',
    url: '/apis/messageSender/reply',
  }),

  // 取得未讀站內信筆數
  getUnreadUserMessage: () => ({
    method: 'POST',
    url: '/apis/messageSender/unreadMessagesCount',
  }),
};
