//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import NavHeader from '~/components/NavHeader';
import NavFooter from '~/components/NavFooter';
import NavAside from '~/components/NavAside';
import LoginDialog from '~/components/Dialog/LoginDialog';
import RegisterDialog from '~/components/Dialog/RegisterDialog';
import RulesDialog from '~/components/Dialog/RulesDialog';
import ActDialog from '~/components/Dialog/ActDialog';
import TermsDialog from '~/components/Dialog/TermsDialog';

export default {
  name: 'App',
  components: {
    NavHeader,
    NavFooter,
    NavAside,
    LoginDialog,
    RegisterDialog,
    RulesDialog,
    ActDialog,
    TermsDialog,
  },
  computed: {
    ...mapGetters('user', ['token']),
  },
  methods: {
    ...mapActions('user', ['logout']),
    ...mapActions('common', [
      'getSkillTree',
      'getBankSelect',
      'getIndustryCategoryTree',
      'getJobCatTree',
      'getAreaLocalTree',
      'getAreaWorldTree',
    ]),
    init() {
      if (this.token) this.getBankSelect();
      this.getSkillTree();
      this.getIndustryCategoryTree();
      this.getJobCatTree();
      this.getAreaLocalTree();
      this.getAreaWorldTree();
    },
  },
  created() {
    //在頁面載入時讀取sessionStorage裡的狀態資訊
    if (process.client) {
      if (sessionStorage.getItem('store')) {
        this.$store.replaceState(
          Object.assign(
            {},
            this.$store.state,
            JSON.parse(sessionStorage.getItem('store')),
          ),
        );
      }

      window.addEventListener('beforeunload', () => {
        sessionStorage.setItem('store', JSON.stringify(this.$store.state));
      });
      this.init();
    }
  },
  mounted() {
    // 到訪網頁時loading
    this.$nextTick(() => {
      this.$nuxt.$loading.start();
      setTimeout(() => this.$nuxt.$loading.finish(), 500);
    });
  },
};
