//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import packageJson from '~~/package.json';
import { social } from '~/utils/social';

export default {
  name: 'NavFooter',
  data() {
    return {
      version: packageJson.version,
      social: social,
    };
  },
};
