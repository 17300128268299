import { render, staticRenderFns } from "./simple.vue?vue&type=template&id=1378f0dd&scoped=true&"
import script from "./simple.vue?vue&type=script&lang=js&"
export * from "./simple.vue?vue&type=script&lang=js&"
import style0 from "./simple.vue?vue&type=style&index=0&id=1378f0dd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1378f0dd",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavHeader: require('/app/src/components/NavHeader/index.vue').default,NavAside: require('/app/src/components/NavAside/index.vue').default,NavFooter: require('/app/src/components/NavFooter/index.vue').default})
