// ############## 企業 ##############
export default {
  // 企業登入
  ownerLogin: () => ({ method: 'POST', url: '/business/auth/login' }),

  // 企業註冊
  ownerRegister: () => ({ method: 'POST', url: '/business/auth/register' }),

  // 企業登出
  ownerLogout: () => ({ method: 'POST', url: '/business/owner/logout' }),

  // 合約列表
  getOwnerContract: () => ({
    method: 'GET',
    url: '/business/contract/listAll',
  }),

  // 科系列表
  getMajorTree: () => ({
    method: 'GET',
    url: '/business/major/list',
  }),

  // ############## 企業資訊 ##############

  // 取得企業資訊
  getOwnerInfo: () => ({ method: 'GET', url: '/business/owner/getOne' }),

  // 更新企業資訊
  updateOwnerInfo: () => ({
    method: 'POST',
    url: '/business/owner/updateOwner',
  }),

  // 公司登記證明以及合約檔案回傳, type = 2(公司登記證明), 3(合約檔案)
  uploadOwnerFile: () => ({
    method: 'FILE',
    url: '/business/owner/uploadFile',
  }),

  // 上傳企業Logo
  uploadOwnerLogo: () => ({
    method: 'FILE',
    url: '/business/owner/uploadLogo',
  }),

  // ############## 企業職缺 ##############

  // 新增職缺
  addOwnerJob: () => ({ method: 'POST', url: '/business/jobPosition/add' }),

  // 刪除職缺
  deleteOwnerJob: () => ({
    method: 'POST',
    url: '/business/jobPosition/delete',
  }),

  // 從連結複製職缺
  parserOwnerJob: () => ({
    method: 'POST',
    url: '/business/jobPosition/parser',
  }),

  // 職缺列表
  getOwnerJobs: () => ({
    method: 'POST',
    url: '/business/jobPosition/findByCondition',
  }),

  // 該職缺求職列表
  getOwnerJobsApplicant: () => ({
    method: 'POST',
    url: '/business/userApplicant/findByCondition',
  }),

  // 取得求職者會員資訊
  getOwnerApplicantUser: () => ({
    method: 'GET',
    url: '/business/userApplicant/getUser',
  }),

  // 更新職缺
  updateOwnerJob: () => ({
    method: 'POST',
    url: '/business/jobPosition/update',
  }),

  // 取得該筆職缺資訊
  getOwnerJob: ({ jobPositionId }) => ({
    method: 'GET',
    url: `/business/jobPosition/${jobPositionId}`,
  }),

  // 新增職缺語言
  addOwnerJobLanguage: () => ({
    method: 'POST',
    url: '/business/jobPositionLanguage/add',
  }),

  // 刪除職缺語言
  removeOwnerJobLanguage: () => ({
    method: 'POST',
    url: '/business/jobPositionLanguage/delete',
  }),

  // 取得職缺語言
  getOwnerJobLanguage: ({ jobPositionId }) => ({
    method: 'GET',
    url: `/business/jobPositionLanguage/list/${jobPositionId}`,
  }),

  // 更新職缺語言
  updateOwnerJobLanguage: () => ({
    method: 'POST',
    url: '/business/jobPositionLanguage/update',
  }),

  // 新增職缺技能
  addOwnerJobSkill: () => ({
    method: 'POST',
    url: '/business/jobPositionSkillMapping/add',
  }),

  // 刪除職缺技能
  removeOwnerJobSkill: () => ({
    method: 'POST',
    url: '/business/jobPositionSkillMapping/delete',
  }),

  // 取得職缺技能
  getOwnerJobSkill: ({ jobPositionId }) => ({
    method: 'GET',
    url: `/business/jobPositionSkillMapping/list/${jobPositionId}`,
  }),

  // 更新職缺技能
  updateOwnerJobSkill: () => ({
    method: 'POST',
    url: '/business/jobPositionSkillMapping/update',
  }),

  // ############## 企業訊息 ##############

  // 發送站內信
  sendOwnerMessage: () => ({
    method: 'POST',
    url: '/business/messageSender/add',
  }),

  // 刪除站內信
  deleteOwnerMessage: () => ({
    method: 'DELETE',
    url: '/business/messageSender/delete',
  }),

  // 取得站內信列表
  getOwnerMessage: () => ({
    method: 'POST',
    url: '/business/messageSender/findByCondition',
  }),

  // 讀取信件
  readOwnerMessage: ({ rId }) => ({
    method: 'PATCH',
    url: `/business/messageSender/read?rId=${rId}`,
  }),

  // 回覆站內信
  replyOwnerMessage: () => ({
    method: 'POST',
    url: '/business/messageSender/reply',
  }),

  // 取得未讀站內信筆數
  getUnreadOwnerMessage: () => ({
    method: 'POST',
    url: '/business/messageSender/unreadMessagesCount',
  }),
};
