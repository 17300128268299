import Vue from 'vue';
import VueFbCustomerChat from 'vue-fb-customer-chat';

if (process.env.isFBChatOpen) {
  Vue.use(VueFbCustomerChat, {
    page_id: 111974394329355, //  change 'null' to your Facebook Page ID,
    theme_color: '#2ea9df', // theme color in HEX
    locale: 'zh_TW', // default 'en_US'
  });
}
