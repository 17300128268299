// initial state
const state = () => ({
  jsonldObj: {},
});

// getters
const getters = {
  jsonldObj: state => state.jsonldObj,
};

// actions
const actions = {
  setJsonldObj({ commit }, payload) {
    commit('SET_JSONLD_OBJ', payload);
  },
};

// mutations
const mutations = {
  SET_JSONLD_OBJ(state, data) {
    state.jsonldObj = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
