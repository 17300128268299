//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import packageJson from '~~/package.json';
import { social } from '~/utils/social';

export default {
  name: 'NavFooter',
  data() {
    return {
      version: packageJson.version,
      social: social,
      sitemaps: [
        { title: '首頁', path: '/' },
        { title: '關於我們', path: '/AboutUs' },
        { title: '職缺博覽會', path: '/Jobs' },
        { title: '精選閱讀', path: '/Article' },
      ],
    };
  },
  methods: {
    ...mapActions('dialog', ['setRulesShow', 'setActShow', 'setTermsShow']),
    goto(path) {
      if (path === this.$route.path) return;
      this.$router.push(path);
    },
    onShowRules() {
      this.setRulesShow(true);
    },
    onShowAct() {
      this.setActShow(true);
    },
    onShowTerms() {
      this.setTermsShow(true);
    },
  },
};
