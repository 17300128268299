//
//
//
//
//
//
//
//

import { throttle } from 'throttle-debounce';

export default {
  name: 'NavAside',
  data() {
    return {
      topShow: false,
    };
  },

  methods: {
    onScroll() {
      if (process.browser) {
        this.topShow = window.pageYOffset > 300;
      }
    },
    onClickButton(url) {
      if (process.browser) {
        window.open(url);
      }
    },
    gotoTop() {
      if (process.browser) {
        window.scroll({
          top: 0,
          behavior: 'smooth',
        });
      }
    },
  },
  mounted() {
    if (process.browser) {
      window.addEventListener('scroll', throttle(500, false, this.onScroll), {
        passive: true,
      });
    }
  },
  destroyed() {
    if (process.browser) {
      window.removeEventListener(
        'scroll',
        throttle(500, false, this.onScroll),
        {
          passive: true,
        },
      );
    }
  },
};
