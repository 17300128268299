import { render, staticRenderFns } from "./other.vue?vue&type=template&id=103badbc&scoped=true&"
import script from "./other.vue?vue&type=script&lang=js&"
export * from "./other.vue?vue&type=script&lang=js&"
import style0 from "./other.vue?vue&type=style&index=0&id=103badbc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "103badbc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavHeader: require('/app/src/components/NavHeader/index.vue').default,NavFooter: require('/app/src/components/NavFooter/index.vue').default})
