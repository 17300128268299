//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      time: 60,
      timer: null,
    };
  },
  computed: {
    ...mapGetters('user', ['captchaImg']),
  },
  methods: {
    ...mapActions('user', ['setCaptchaImg']),
    counter() {
      this.time = this.time - 1;
      if (this.time < 1) {
        clearInterval(this.timer);
        this.$emit('overTime', true);
      }
    },
    startCount() {
      this.time = 60;
      this.counter();
      this.$emit('overTime', false);

      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(this.counter, 1000);
    },
    handleReset() {
      this.setCaptchaImg();
      this.startCount();
    },
  },
  watch: {
    captchaImg() {
      this.startCount();
    },
  },
  mounted() {
    this.startCount();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
