import { render, staticRenderFns } from "./RulesDialog.vue?vue&type=template&id=53c27dac&scoped=true&"
import script from "./RulesDialog.vue?vue&type=script&lang=js&"
export * from "./RulesDialog.vue?vue&type=script&lang=js&"
import style0 from "./RulesDialog.vue?vue&type=style&index=0&id=53c27dac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53c27dac",
  null
  
)

export default component.exports