import { Message, MessageBox } from 'element-ui';

// initial state
const state = () => ({
  token: null,
  refreshToken: null,
  menu: null,
  type: null,
  userName: null,
  userEngName: null,
  userIdNumber: null,
  email: null,
  avatarUrl: null,
  oid: null,
  userCategory: null, // 0 = 會員, 1 = 企業, 2 = 企訓(講師)
  referCode: null,
  captchaCode: null,
  captchaImg: null,
  unreadMessage: null,
  userStatus: null,
  tokenExpired: false,
});

// getters
const getters = {
  token: state => state.token,
  refreshToken: state => state.refreshToken,
  menu: state => state.menu,
  type: state => state.type,
  userName: state => state.userName,
  userEngName: state => state.userEngName,
  userIdNumber: state => state.userIdNumber,
  email: state => state.email,
  avatarUrl: state => state.avatarUrl,
  oid: state => state.oid,
  userCategory: state => state.userCategory,
  referCode: state => state.referCode,
  captchaCode: state => state.captchaCode,
  captchaImg: state => state.captchaImg,
  unreadMessage: state => state.unreadMessage,
  userStatus: state => state.userStatus,
  tokenExpired: state => state.tokenExpired,
};

// actions
const actions = {
  setToken({ commit }, payload) {
    commit('SET_LOGIN_TOKEN', payload);
  },
  setMenu({ commit }, payload) {
    commit('SET_LOGIN_MENU', payload);
  },
  setType({ commit }, payload) {
    commit('SET_LOGIN_MASTER', payload);
  },
  setUserName({ commit }, payload) {
    commit('SET_LOGIN_USERNAME', payload);
  },
  setUserEngName({ commit }, payload) {
    commit('SET_LOGIN_USERENGNAME', payload);
  },
  setUserIdNumber({ commit }, payload) {
    commit('SET_LOGIN_USERIDNUMBER', payload);
  },
  setEmail({ commit }, payload) {
    commit('SET_LOGIN_EMAIL', payload);
  },
  setAvatarUrl({ commit }, payload) {
    commit('SET_LOGIN_AVATAR_URL', payload);
  },
  setOid({ commit }, payload) {
    commit('SET_LOGIN_OID', payload);
  },
  setUserCategory({ commit }, payload) {
    commit('SET_LOGIN_USER_CATEGORY', payload);
  },
  setReferCode({ commit }) {
    this.$api('userGetReferCode').then(data => {
      commit('SET_REFER_CODE', data);
    });
  },
  setCaptchaImg({ commit }, noMessage = true) {
    this.$api('captchaImg').then(data => {
      commit('SET_CAPTCHA_CODE', data.code);
      commit('SET_CAPTCHA_IMG', data.image);
      if (noMessage) Message.success('驗證碼已刷新');
    });
  },
  setUnreadMessage({ commit }, payload) {
    commit('SET_UNREAD_MESSAGE', payload);
  },
  setUserStatus({ commit }, payload) {
    commit('SET_USER_STATUS', payload);
  },
  logout({ commit }, type) {
    if (type === 'expired') {
      commit('TOKEN_EXPIRED');
    }

    if (type === 'userClick') {
      Message.success('帳號已登出');
    }

    if (type === 'reset') {
      Message.success('密碼已重設，請重新登入');
    }

    commit('CLEAR_LOGIN_TOKEN');
    setTimeout(() => {
      if ($nuxt.$route.path !== '/') this.$router.replace('/');
    }, 300);
  },
  loginFirst({ dispatch, getters }, { callback, category }) {
    if (!getters['token']) {
      return MessageBox('尚未登入, 請先登入', '', {
        type: 'warning',
      }).then(() => {
        dispatch('dialog/setLoginShow', true, { root: true });
      });
    }

    // 若非指定登入身份
    if (category !== (getters['userCategory'] || 0)) {
      return Message.warning(
        `請先登入或切換身份至${['會員', '企業', '講師'][category]}`,
      );
    }
    callback();
  },
};

// mutations
const mutations = {
  SET_LOGIN_TOKEN(state, data) {
    const { accessToken, refreshToken } = data;
    sessionStorage.setItem('_loginToken', accessToken);
    sessionStorage.setItem('_refreshToken', refreshToken);
    state.token = accessToken;
    state.refreshToken = refreshToken;
  },
  SET_LOGIN_MENU(state, data) {
    sessionStorage.setItem('_loginMenu', data);
    state.menu = data;
  },
  SET_LOGIN_MASTER(state, data) {
    sessionStorage.setItem('_loginType', data);
    state.type = data;
  },
  SET_LOGIN_USERNAME(state, data) {
    sessionStorage.setItem('_loginUserName', data);
    state.userName = data;
  },
  SET_LOGIN_USERENGNAME(state, data) {
    sessionStorage.setItem('_loginUserEngName', data);
    state.userEngName = data;
  },
  SET_LOGIN_USERIDNUMBER(state, data) {
    sessionStorage.setItem('_loginUserIdNumber', data);
    state.userIdNumber = data;
  },
  SET_LOGIN_EMAIL(state, data) {
    sessionStorage.setItem('_loginEmail', data);
    state.email = data;
  },
  SET_LOGIN_AVATAR_URL(state, data) {
    sessionStorage.setItem('_loginAvatarUrl', data);
    state.avatarUrl = data;
  },
  SET_LOGIN_OID(state, data) {
    sessionStorage.setItem('_loginOid', data);
    state.oid = data;
  },
  SET_LOGIN_USER_CATEGORY(state, data) {
    sessionStorage.setItem('_loginUserCategory', data);
    state.userCategory = data;
  },
  SET_REFER_CODE(state, data) {
    sessionStorage.setItem('_referCode', data);
    state.referCode = data;
  },
  SET_CAPTCHA_CODE(state, data) {
    sessionStorage.setItem('_captchaCode', data);
    state.captchaCode = data;
  },
  SET_CAPTCHA_IMG(state, data) {
    sessionStorage.setItem('_captchaImg', data);
    state.captchaImg = data;
  },
  SET_UNREAD_MESSAGE(state, data) {
    sessionStorage.setItem('_unreadMessage', data);
    state.unreadMessage = data;
  },
  SET_USER_STATUS(state, data) {
    sessionStorage.setItem('_userStatus', data);
    state.userStatus = data;
  },
  CLEAR_LOGIN_TOKEN(state) {
    sessionStorage.removeItem('_loginToken');
    sessionStorage.removeItem('_loginMenu');
    sessionStorage.removeItem('_loginType');
    sessionStorage.removeItem('_loginUserName');
    sessionStorage.removeItem('_loginUserEngName');
    sessionStorage.removeItem('_loginUserIdNumber');
    sessionStorage.removeItem('_loginEmail');
    sessionStorage.removeItem('_loginAvatarUrl');
    sessionStorage.removeItem('_loginOid');
    sessionStorage.removeItem('_loginUserCategory');
    sessionStorage.removeItem('_referCode');
    sessionStorage.removeItem('_captchaCode');
    sessionStorage.removeItem('_captchaImg');
    sessionStorage.removeItem('_userStatus');
    state.token = null;
    state.menu = null;
    state.type = null;
    state.userName = null;
    state.userEngName = null;
    state.userIdNumber = null;
    state.email = null;
    state.avatarUrl = null;
    state.oid = null;
    state.userCategory = null;
    state.referCode = null;
    state.captchaCode = null;
    state.captchaImg = null;
    state.userStatus = null;
  },
  TOKEN_EXPIRED(state) {
    if (!state.tokenExpired) {
      state.tokenExpired = true;
      Message.error('帳號授權己過期,請重新登入');
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
