//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import dialogMixin from '@/mixin/dialog.js';
import {
  required,
  email,
  idNumber,
  password,
  taxId,
  phone,
} from '~/utils/validation.js';
import Captcha from '~/components/Captcha';
import GoogleLogin from '~/components/LoginButton/GoogleLogin';
import { cleanObj } from '~/utils';

export default {
  name: 'RegisterDialog',
  mixins: [dialogMixin],
  components: { Captcha, GoogleLogin },
  data() {
    return {
      loadingBtn: false,
      statute: false,
      isTHub: 0,
      dataForm: {},
      isPreparation: false,
      isCaptchaOverTime: false,
      lecturerExperienceLt: '',
      lecturerfileInfoLt: [],
      rules: {
        email: [required, email],
        password: [required, password],
        checkPassword: [required, password],
        captcha: [required],
      },
      appendRules: {
        name: [required],
        phoneNumber: [required, phone],
        ownerName: [required],
        taxId: [required, taxId],
        username: [required, idNumber],
      },
    };
  },
  computed: {
    ...mapGetters('user', ['captchaCode', 'captchaImg']),
    ...mapGetters('dialog', ['registerShow', 'registerActiveTab']),
    ...mapGetters('lecturer', ['teachingFields']),
    isGoogleLoginOpen() {
      return process.env.isGoogleLoginOpen;
    },
    isBusinessOpen() {
      return process.env.isBusinessOpen;
    },
    isLecturerOpen() {
      return process.env.isLecturerOpen;
    },
    isMember() {
      return this.registerActiveTab === 0;
    },
    isOwner() {
      return this.registerActiveTab === 1;
    },
    isLecturer() {
      return this.registerActiveTab === 2;
    },
    labelPosition() {
      if (process.browser) {
        return window.innerWidth > 768 ? 'left' : 'top';
      }
      return 'left';
    },
  },
  watch: {
    // 勾選暫無統編時 清除統編校驗
    isPreparation() {
      if (this.isPreparation) this.$refs['dataForm'].clearValidate('taxId');
    },
  },
  methods: {
    ...mapActions('user', ['setCaptchaImg']),
    ...mapActions('dialog', [
      'setRegisterShow',
      'setRegisterActiveTab',
      'setRulesShow',
      'setLoginShow',
    ]),
    ...mapActions('lecturer', ['getTeachingFields']),
    onClickTab(value) {
      this.setRegisterActiveTab(value);
      this.$refs['dataForm'].clearValidate();
    },
    registerApi(path, payload) {
      this.$api(path, payload)
        .then(data => {
          if (data) this.onClose();
          return this.$message.success('新增成功！請登入開始使用！');
        })
        .catch(error => {
          this.setCaptchaImg();
        });
    },
    handleRegister() {
      this.$refs['dataForm'].validate(valid => {
        if (!valid) {
          return null;
        }

        if (this.isCaptchaOverTime) {
          this.$message.error('驗證碼已過期，請刷新後再次輸入');
          return false;
        }

        if (this.dataForm.password !== this.dataForm.checkPassword)
          return this.$message.warning('請確認兩次密碼是否相同');

        if (!this.statute) return this.$message.warning('請閱讀使用規約並勾選');

        const basicPayload = {
          code: this.captchaCode,
          captcha: this.dataForm.captcha.replace(/ /g, ''),
        };

        // 會員註冊
        if (this.isMember) {
          const memberPayload = {
            ...basicPayload,
            email: this.dataForm.email,
            username: this.dataForm.username,
            password: this.dataForm.password,
            source: 1,
          };

          return this.registerApi('userRegister', memberPayload);
        }

        // 企業註冊
        if (this.isOwner) {
          const ownerPayload = {
            ...basicPayload,
            ownerName: this.dataForm.ownerName,
            taxId: this.isPreparation ? null : this.dataForm.taxId,
            email: this.dataForm.email,
            password: this.dataForm.password,
            type: this.isTHub,
          };

          return this.registerApi('ownerRegister', cleanObj(ownerPayload));
        }

        // 講師註冊
        if (this.isLecturer) {
          const lecturerPayload = {
            ...basicPayload,
            name: this.dataForm.name,
            phoneNumber: this.dataForm.phoneNumber,
            email: this.dataForm.email,
            password: this.dataForm.password,
            lecturerTeachingFieldIdLt: this.dataForm.lecturerTeachingFieldIdLt,
            lecturerExperienceLt: [this.lecturerExperienceLt],
            lecturerfileInfoLt: this.lecturerfileInfoLt,
          };

          if (this.lecturerExperienceLt.trim() === '')
            delete payload.lecturerExperienceLt;

          return this.registerApi('lecturerRegister', lecturerPayload);
        }
      });
    },
    handleGoogleLogin() {
      this.setRegisterShow(false);
      this.close();
    },
    onClickRegister() {
      this.$gtag.event('註冊', {
        event_category: '按鈕點擊',
        event_label: '註冊',
        value: this.dataForm.username,
      });
      return this.handleRegister();
    },
    onOpen() {
      this.setCaptchaImg(false);
    },
    openLogin() {
      this.onClose();
      this.setLoginShow(true);
      this.$gtag.event('打開登入彈窗', {
        event_category: '按鈕點擊',
        event_label: '打開登入彈窗',
        value: this.userName,
      });
    },
    onClose() {
      this.statute = false;
      this.dataForm = {};
      this.$refs['dataForm'].clearValidate();
      this.setRegisterShow(false);
    },
    onShowRules() {
      this.setRulesShow(true);
    },
    onCloseRules() {
      this.setRulesShow(false);
    },
    captchaOverTime(value) {
      this.isCaptchaOverTime = value;
    },
    // Upload
    onUploadCallback(file) {
      let f = {
        fileName: file.name,
        fileUrl: file.src,
        uid: file.uid,
      };
      this.lecturerfileInfoLt.push(f);
    },
    onDeleteCallback(file) {
      this.lecturerfileInfoLt = this.lecturerfileInfoLt.filter(
        f => f.uid != file.uid,
      );
    },
    onUploadUserFile(payload, callback) {
      this.$api('lecturerRegisterUploadFile', payload, callback);
    },
  },
  created() {
    // this.getTeachingFields();
  },
};
