//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'App',
  created() {
    //在頁面載入時讀取sessionStorage裡的狀態資訊
    if (process.client) {
      if (sessionStorage.getItem('store')) {
        this.$store.replaceState(
          Object.assign(
            {},
            this.$store.state,
            JSON.parse(sessionStorage.getItem('store')),
          ),
        );
      }

      window.addEventListener('beforeunload', () => {
        sessionStorage.setItem('store', JSON.stringify(this.$store.state));
      });
    }
  },
};
