//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { fileDownload } from '~/utils';
export default {
  name: 'UploadRegister',
  props: {
    fileSrc: { type: String, default: '' },
    buttonText: { type: String, default: '' },
    buttonType: { type: String, default: '' },
    tip: { type: String, default: '' },
    noTip: { type: Boolean, default: false },
    uploadFile: { type: Function },
    uploadCallback: { type: Function },
    beforeUpload: { type: Function },
  },
  data() {
    return {
      file: null,
      uploadSrc: '',
    };
  },
  watch: {
    fileSrc() {
      this.uploadSrc = this.fileSrc;
    },
  },
  methods: {
    handleUploadFile(payload) {
      this.file = payload.file;

      this.uploadFile(
        {
          file: payload.file,
        },
        this.getFileList,
      );
    },
    handleBeforeUpload(file) {
      if (file.size > 2 * 1024 * 1024) {
        this.$message.error('上傳檔案大小不能超過 2MB!');
        return false;
      }
      if (this.beforeUpload) this.beforeUpload();
      this.uploadSrc = URL.createObjectURL(file);
      return true;
    },
    handleRemoveFle() {
      this.uploadSrc = '';
      this.$refs.upload.clearFiles();
      this.$emit('deleteCallback', this.file);
    },
    handleDownload() {
      fileDownload(this.uploadSrc, this.file.name);
    },
    getFileList(src) {
      this.file.src = src;
      this.$emit('uploadCallback', this.file);
    },
  },
};
