//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PageNotFound',
  methods: {
    gotoHome() {
      this.$router.push('/');
    },
  },
};
