// initial state
const state = () => ({
  basicData: {},
  isEdit: false,
});

// getters
const getters = {
  basicData: state => state.basicData,
  isEdit: state => state.isEdit,
};

// actions
const actions = {
  setBasicData({ commit }, payload) {
    commit('SET_BASIC_DATA', payload);
  },
};

// mutations
const mutations = {
  SET_BASIC_DATA(state, data) {
    state.basicData = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
