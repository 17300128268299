export default {
  // ############## 講師資訊 ##############

  // 講師登入
  lecturerLogin: () => ({ method: 'POST', url: '/lecturer/auth/login' }),

  // 講師註冊
  lecturerRegister: () => ({ method: 'POST', url: '/lecturer/auth/register' }),

  // 講師登出
  lecturerLogout: () => ({ method: 'POST', url: '/lecturer/lecturer/logout' }),

  // 講師驗證碼圖片
  lecturerCaptchaImg: () => ({ method: 'GET', url: '/lecturer/auth/captcha' }),

  // 講師上傳履歷檔案
  lecturerRegisterUploadFile: () => ({
    method: 'FILE',
    url: '/lecturer/auth/uploadFile',
  }),

  // 移除講師相關檔案
  deleteLecturerFiles: () => ({
    method: 'DELETE',
    url: `/lecturer/lecturer/files`,
  }),

  // 取得講師相關檔案
  getLecturerFiles: () => ({ method: 'GET', url: '/lecturer/lecturer/files' }),

  // 該講師資訊(需登入狀態)
  getLecturer: () => ({
    method: 'GET',
    url: '/lecturer/lecturer/getOne',
  }),

  // 更新講師資訊
  updateLecturer: () => ({
    method: 'POST',
    url: '/lecturer/lecturer/update',
  }),

  // 上傳講師相關檔案
  uploadLecturerFiles: () => ({
    method: 'FILE',
    url: '/lecturer/lecturer/upload',
  }),

  // 上傳講師頭像
  uploadLecturerAvatar: () => ({
    method: 'FILE',
    url: '/lecturer/lecturer/uploadPic',
  }),

  // ############## 講師經歷 ##############

  // 新增講師經歷
  addLecturerExperience: () => ({
    method: 'POST',
    url: '/lecturer/lecturerExperience/add',
  }),

  // 刪除講師經歷
  deleteLecturerExperience: () => ({
    method: 'POST',
    url: '/lecturer/lecturerExperience/delete',
  }),

  // 取得講師經歷
  getLecturerExperience: () => ({
    method: 'GET',
    url: '/lecturer/lecturerExperience/list',
  }),

  // 更新講師經歷
  updateLecturerExperience: () => ({
    method: 'POST',
    url: '/lecturer/lecturerExperience/update',
  }),

  // ############## 企訓首頁 ##############

  // 課程列表
  getLecturerCourse: () => ({
    method: 'POST',
    url: '/lecturer/course/findByCondition',
  }),

  // 講師列表
  getLecturerList: () => ({
    method: 'POST',
    url: '/lecturer/lecturerAuth/findByCondition',
  }),

  // 取得輪播講師列表
  getLecturerCarouselList: () => ({
    method: 'GET',
    url: '/lecturer/lecturerAuth/getCarouselLecturer',
  }),

  // 取得講師資訊
  getLecturerAuth: () => ({
    method: 'GET',
    url: '/lecturer/lecturerAuth/getOne',
  }),

  // ############## 講師收藏課程 ##############

  // 講師收藏課程列表
  getCourseCollect: () => ({
    method: 'POST',
    url: '/lecturer/lecturerCollectCourse/findByCondition',
  }),

  // 講師收藏課程
  addCourseCollect: () => ({
    method: 'POST',
    url: '/lecturer/lecturerCollectCourse/collect',
  }),

  // 移除講師收藏課程
  removeCourseCollect: () => ({
    method: 'POST',
    url: '/lecturer/lecturerCollectCourse/removeCollect',
  }),

  // ############## 講師申請課程 ##############

  // 講師申請課程列表
  getCourseJoin: () => ({
    method: 'POST',
    url: '/lecturer/lecturerJoinCourse/findByCondition',
  }),

  // 講師申請課程
  addCourseJoin: () => ({
    method: 'POST',
    url: '/lecturer/lecturerJoinCourse/join',
  }),

  // 移除講師申請課程
  removeCourseJoin: () => ({
    method: 'POST',
    url: '/lecturer/lecturerJoinCourse/removeJoin',
  }),

  // 該講師課程企業評價列表
  getLecturerOwnerEvaluation: () => ({
    method: 'POST',
    url: '/lecturer/lecturerOwnerEvaluationAuth/findByCondition',
  }),

  // ############## 講師出版著作 ##############

  // 新增講師出版著作
  addLecturerPublish: () => ({
    method: 'POST',
    url: '/lecturer/lecturerPublish/add',
  }),

  // 刪除講師出版著作
  deleteLecturerPublish: () => ({
    method: 'POST',
    url: '/lecturer/lecturerPublish/delete',
  }),

  // 取得講師出版著作
  getLecturerPublish: () => ({
    method: 'GET',
    url: '/lecturer/lecturerPublish/list',
  }),

  // 更新講師出版著作
  updateLecturerPublish: () => ({
    method: 'POST',
    url: '/lecturer/lecturerPublish/update',
  }),

  // ############## 講師所屬領域 ##############

  // 新增該講師所屬領域
  addLecturerTeachFieldMapping: () => ({
    method: 'POST',
    url: '/lecturer/lecturerTeachFieldMapping/add',
  }),

  // 刪除該講師所屬領域
  deleteLecturerTeachFieldMapping: () => ({
    method: 'POST',
    url: '/lecturer/lecturerTeachFieldMapping/delete',
  }),

  // 取得該講師所屬領域相關資訊
  getLecturerTeachFieldMapping: () => ({
    method: 'GET',
    url: '/lecturer/lecturerTeachFieldMapping/list',
  }),

  // 授課領域下拉式選單
  getLecturerTeachingFieldOption: () => ({
    method: 'GET',
    url: '/lecturer/teachingField/selectOption',
  }),

  // ############## 講師授課企業 ##############

  // 新增講師授課企業
  addLecturerTeachOwner: () => ({
    method: 'POST',
    url: '/lecturer/lecturerTeachOwner/add',
  }),

  // 刪除講師授課企業
  deleteLecturerTeachOwner: () => ({
    method: 'POST',
    url: '/lecturer/lecturerTeachOwner/delete',
  }),

  // 取得講師授課企業
  getLecturerTeachOwner: () => ({
    method: 'GET',
    url: '/lecturer/lecturerTeachOwner/list',
  }),

  // 更新講師授課企業
  updateLecturerTeachOwner: () => ({
    method: 'POST',
    url: '/lecturer/lecturerTeachOwner/update',
  }),

  // ############## 講師相關網站 ##############

  // 新增講師相關網站
  addLecturerWebsite: () => ({
    method: 'POST',
    url: '/lecturer/lecturerWebsite/add',
  }),

  // 刪除講師相關網站
  deleteLecturerWebsite: () => ({
    method: 'POST',
    url: '/lecturer/lecturerWebsite/delete',
  }),

  // 取得講師相關網站
  getLecturerWebsite: () => ({
    method: 'GET',
    url: '/lecturer/lecturerWebsite/list',
  }),

  // 更新講師相關網站
  updateLecturerWebsite: () => ({
    method: 'POST',
    url: '/lecturer/lecturerWebsite/update',
  }),

  // ############## 企業訊息 ##############

  // 發送站內信
  sendLecturerMessage: () => ({
    method: 'POST',
    url: '/lecturer/messageSender/add',
  }),

  // 刪除站內信
  deleteLecturerMessage: () => ({
    method: 'DELETE',
    url: '/lecturer/messageSender/delete',
  }),

  // 取得站內信列表
  getLecturerMessage: () => ({
    method: 'POST',
    url: '/lecturer/messageSender/findByCondition',
  }),

  // 讀取信件
  readLecturerMessage: ({ rId }) => ({
    method: 'PATCH',
    url: `/lecturer/messageSender/read?rId=${rId}`,
  }),

  // 回覆站內信
  replyLecturerMessage: () => ({
    method: 'POST',
    url: '/lecturer/messageSender/reply',
  }),

  // 取得未讀站內信筆數
  getUnreadLecturerMessage: () => ({
    method: 'POST',
    url: '/lecturer/messageSender/unreadMessagesCount',
  }),
};
