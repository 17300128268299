// initial state
const state = () => ({
  teachingFields: [],
  teachingFieldsMenu: [],
});

// getters
const getters = {
  teachingFields: state => state.teachingFields,
  teachingFieldsMenu: state => state.teachingFieldsMenu,
};

// actions
const actions = {
  getTeachingFields({ commit }) {
    this.$api('getLecturerTeachingFieldOption').then(data => {
      const sliceData = data.slice(0, 3);
      const teacherMenu = [{ label: '全部', value: null }, ...sliceData];

      commit('GET_TEACHING_FIELDS', data);
      commit('GET_TEACHING_FIELDS_MENU', teacherMenu);
    });
  },
};

// mutations
const mutations = {
  GET_TEACHING_FIELDS(state, data) {
    sessionStorage.setItem('_teachingFields', data);
    state.teachingFields = data;
  },
  GET_TEACHING_FIELDS_MENU(state, data) {
    sessionStorage.setItem('_teachingFieldsMenu', data);
    state.teachingFieldsMenu = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
